import { Outlet, Link } from 'react-router-dom';
import styles from './Layout.module.css';
import Contoso from '../../assets/Contoso.svg';
import { Stack } from '@fluentui/react';
import { useContext, useEffect, useState } from 'react';
import { HistoryButton, ShareButton, ThemeButton } from '../../components/common/Button';
import { AppStateContext } from '../../state/AppProvider';
import { CosmosDBStatus } from '../../api';

const Layout = () => {
  const [copyClicked, setCopyClicked] = useState<boolean>(false);
  const [copyText, setCopyText] = useState<string>('Copy URL');
  const [hideHistoryLabel, setHideHistoryLabel] = useState<string>('Hide chat history');
  const [showHistoryLabel, setShowHistoryLabel] = useState<string>('Show chat history');
  const appStateContext = useContext(AppStateContext);
  const ui = appStateContext?.state.frontendSettings?.ui;

  const handleAppThemeClick = () => {
    appStateContext?.dispatch({ type: 'TOGGLE_APP_THEME' });
  };

  const handleHistoryClick = () => {
    appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' });
  };

  useEffect(() => {
    if (copyClicked) {
      setCopyText('Copied URL');
    }
  }, [copyClicked]);

  useEffect(() => {}, [appStateContext?.state.isCosmosDBAvailable.status]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        setHideHistoryLabel('Hide history');
        setShowHistoryLabel('Show history');
      } else {
        setHideHistoryLabel('Hide chat history');
        setShowHistoryLabel('Show chat history');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={styles.layout}>
      <header className={styles.header} role={'banner'}>
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
          <Stack horizontal verticalAlign="center">
            <embed src={ui?.logo ? ui.logo : Contoso} className={styles.headerIcon} aria-hidden="true" />
            <Link to="/" className={styles.headerTitleContainer}>
              <h1 className={styles.headerTitle}>{ui?.title}</h1>
            </Link>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 4 }} className={styles.shareButtonContainer}>
          </Stack>
        </Stack>
      </header>
      <Outlet />
    </div>
  );
};

export default Layout;
