import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import Login from "./pages/login/Login";
import { AppStateProvider } from "./state/AppProvider";
import { login } from "./api";
import "./index.css";

initializeIcons();
export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginMessage, setLoginMessage] = useState<string | null>(null);

  /**
   * Sends the password from the login form to the API for authentication
   * Sets a cookie with an expiration time of 1 hour after a
   * successful login
   */
  const handleLogin = async (password: string) => {
    const passedAuth = await login(password);
    if (passedAuth) {
      setIsLoggedIn(true);
      const now = new Date();
      const expires = new Date(now.getTime() + 60 * 60 * 1000).toUTCString();
      document.cookie = `loginTime=${now.toISOString()}; expires=${expires}; path=/`;
    } else {
      setIsLoggedIn(false);
      setLoginMessage("Login failed. Please try again.");
    }
  };

  /**
   * Checks if the login cookie is still valid
   */
  useEffect(() => {
    const cookies = document.cookie.split("; ");
    const loginTimeCookie = cookies.find((cookie) => cookie.startsWith("loginTime="));

    if (loginTimeCookie) {
      const loginTimeValue = loginTimeCookie.split("=")[1];
      const loginTime = new Date(loginTimeValue);
      const now = new Date();
      if (now.getTime() < loginTime.getTime() + 60 * 60 * 1000) {
        console.log("Cookie is still valid");
        setIsLoggedIn(true);
      } else {
        console.log("Cookie has expired");
        setIsLoggedIn(false);
      }
    }
  }, []);

  return (
    <AppStateProvider>
      {isLoggedIn ? (
        <HashRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Chat />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </HashRouter>
      ) : (
        <Login onLogin={handleLogin} validationMessage={loginMessage} />
      )}
    </AppStateProvider>
  );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
