import React, { useEffect, useState } from "react";
import styles from "./Login.module.css";

function Login({
  onLogin,
  validationMessage,
}: {
  onLogin: (password: string) => void;
  validationMessage: string | null;
}) {
  const [password, setPassword] = useState("");
  const [notification, setNotification] = useState<string | null>(null);
  const showNotification = validationMessage || notification;

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (password === "") {
      setNotification("Please enter a password");
      return;
    }
    onLogin(password);
  };

  useEffect(() => {
    setNotification(validationMessage || null);
  }, [validationMessage]);

  return (
    <div className={styles.loginContainer}>
      <div className={styles.headerContainer}>
        <h2>Please login</h2>
      </div>

      <form onSubmit={handleSubmit} className={styles.loginForm}>
        {showNotification && (
          <div className={styles.notification}>
            <p>{notification}</p>
          </div>
        )}

        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;
